/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Newsletter from "components/Newsletter"

import { ContentSingle, Other } from "page_components/blog"

const Post = ({ pageContext, data, location }) => {
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)
  const [filteredData, setFilteredData] = useState(null)

  useEffect(() => {
    setFilteredData(
      data?.allWpPost?.nodes
        ?.filter(item => item.id !== pageContext.data.id)
        .slice(0, 3)
    )
  }, [])

  return (
    <Layout location={location} isHeaderTransparent>
      <ContentSingle data={pageContext?.data?.data} />
      <Other data={filteredData} />
      <Newsletter />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const Head = ({ pageContext }) => (
  <Seo
    title={
      pageContext?.data?.data?.seo?.title
        ? pageContext?.data?.data?.seo?.title
        : pageContext?.data?.data?.name
    }
    description={
      pageContext?.data?.data?.seo?.metaDesc &&
      pageContext?.data?.data?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPost {
      nodes {
        id
        title
        uri
        slug
        excerpt
        date
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Post
